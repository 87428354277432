'use strict'

import store from '@/store'

const LABELLEVIE_FRONT_BASE_URL = process.env.VUE_APP_LABELLEVIE_FRONT_BASE_URL
const BAMCOURSES_FRONT_BASE_URL = process.env.VUE_APP_BAMCOURSES_FRONT_BASE_URL
const FRICHTIMARKET_FRONT_BASE_URL = process.env.VUE_APP_FRICHTIMARKET_FRONT_BASE_URL
const FRICHTICO_FRONT_BASE_URL = process.env.VUE_APP_FRICHTICO_FRONT_BASE_URL
const FRICHTIEVENTS_FRONT_BASE_URL = process.env.VUE_APP_FRICHTIEVENTS_FRONT_BASE_URL

const _getSite = (siteId) => {
  return store.getSite(siteId) || { id: siteId, name: '?' }
}

const _codeSitesInfos = {
  1: { variant: 'labellevie', text: 'LBV', name: 'La belle vie' },
  8: { variant: 'bamcourses', text: 'BAM', name: 'Bam courses - Express' },
  9: { variant: 'bamcourses', text: 'BAM', name: 'Bam courses' },
  10: { variant: 'labellevie', text: 'LBV', name: 'La belle vie - Express' },
  11: { variant: 'bamcourses', text: 'BAM', name: 'Bam courses - Android' },
  1012: { variant: 'lgep', text: 'LGEP', name: 'La Grande Epicerie de Paris' },
  1013: { variant: 'frichti', text: 'FRICHTI market', name: 'Frichti market' },
  1015: { variant: 'fco', text: 'FRICHTI.co', name: 'Frichti.co' },
  1014: { variant: 'frichtievents', text: 'FRICHTI Events', name: 'Frichti events' }
}

const _getFrontBaseUrl = (siteId) => {
  switch (siteId) {
    case 8 :
    case 9 :
    case 11 :
    case 'bamcourses' :
      return BAMCOURSES_FRONT_BASE_URL
    case 1013 :
    case 'frichti' :
      return FRICHTIMARKET_FRONT_BASE_URL
    case 1015 :
    case 'fco' :
      return FRICHTICO_FRONT_BASE_URL
    case 1014 :
    case 'frichtievents' :
      return FRICHTIEVENTS_FRONT_BASE_URL
    case 1 :
    case 10 :
    case 'labellevie' :
      return LABELLEVIE_FRONT_BASE_URL
    default :
      return ''
  }
}

export default {
  getCodeSitesInfos: () => _codeSitesInfos,
  getCodeSiteInfos: (siteId) => _codeSitesInfos[siteId] || { variant: '?', text: '?', name: '?' },
  getSite: _getSite,
  getFrontBaseUrl: _getFrontBaseUrl
}
