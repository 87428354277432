'use strict'

import { ApiDeleev, ApiUtils } from '../index'
import mObjects from '../../mixins/objects'
import mString from '../../mixins/string'

const _formatSuppliers = (suppliers) => {
  let _formatted = []
  let _indexed = {}
  suppliers.forEach(o => {
    o.search = mString.slugify(o.name)
    o.by_centers.sort((a, b) => a.center > b.center ? 1 : -1)
  })
  suppliers.forEach(o => {
    o.by_centers_indexed = o.by_centers.reduce((routineMap, supplierCenter) => {
      routineMap[supplierCenter.center] = supplierCenter
      return routineMap
    }, {})
  })
  suppliers = mObjects.sort(suppliers, 'name')
  _formatted = suppliers
    .filter(o => { return !o.is_hidden })
    .map(o => mObjects.pick(o, ['id', 'name', 'category', 'is_internal', 'search']))
  suppliers.forEach((o, idx) => { _indexed[o.id] = idx })

  return {
    suppliers: suppliers,
    suppliers_formatted: _formatted,
    suppliers_indexed: _indexed
  }
}

export default {
  getSuppliers () {
    return ApiUtils.promiseGet(ApiDeleev, '/suppliers/', {})
      .then(response => _formatSuppliers(response))
  }
}
