'use strict'

import { ApiDeleev, ApiUtils } from '../index'

const _formatCenters = (centers) => {
  let _indexed = {}
  centers.forEach((o, idx) => {
    o.is_hidden = o.status === 0
    _indexed[o.id] = idx
  })
  let sortedCenters = centers.slice()
  // default first, then superior status, then lexicographic on name
  sortedCenters.sort((a, b) => {
    if (a.is_default) { return -1 }
    if (b.is_default) { return 1 }
    if (a.status > b.status) { return -1 }
    if (a.status < b.status) { return 1 }
    return a.name.localeCompare(b.name)
  })
  return {
    centers: centers,
    centers_indexed: _indexed,
    sortedCenters: sortedCenters
  }
}
export default {
  getCenters () {
    return ApiUtils.promiseGet(ApiDeleev, '/centers/', { params: { limit: 50 } }).then(function (response) {
      return _formatCenters(response.results.filter(o => o.status > 0))
    })
  }
}
