'use strict'

import { ApiDeleev, ApiUtils } from '../index'
import mObjects from '../../mixins/objects'
import mString from '../../mixins/string'

const _brandsResponse = (data) => {
  let _formatted = data.map(o => Object.assign({ search: mString.slugify(o.name) }, o))
  _formatted = mObjects.sort(_formatted, 'name')
  let _indexed = {}
  _formatted.forEach((o, idx) => { _indexed[o.id] = idx })
  return { brands: data, brands_formatted: _formatted, brands_indexed: _indexed }
}

export default {
  getProducts (params) {
    return ApiUtils.promiseGet(ApiDeleev, '/products/', { params: params || {} })
  },
  getProductById (id) {
    return ApiUtils.promiseGet(ApiDeleev, `/admin-products/nesteds/${id}/`, { params: { visual_stock_quantity: 1, validation: 1 } })
  },
  getBrands () {
    let _brands = []
    let _params = { page: 1 }
    const _getBrands = () => {
      return ApiUtils.promiseGet(ApiDeleev, '/admin-products/brands/', { params: _params })
    }

    const _loop = async () => {
      let response = null
      do {
        response = await _getBrands()
        _brands = _brands.concat(response.results)
        _params.page++
      } while (response.next)

      return _brandsResponse(_brands)
    }
    return _loop()
  }
}
