'use strict'

import { ApiDeleev, ApiUtils } from '../index'
import mString from '../../mixins/string'

let _formatted = []
let _indexed = {}
const _formatCategories = (categories, root, parent, level, thumb, notActive, key, parentId) => {
  categories.forEach(category => {
    _formatted.push({
      id: category.id,
      parent_id: parentId,
      is_active: !notActive && category.is_active,
      name: category.name_web,
      thumb: category.picture_mobile || thumb || '',
      level: level,
      root: root,
      parent: parent,
      search: mString.slugify([root ? (root + ' / ') : '', parent || '', category.name_web].join('')),
      descendents: category.descendent ? category.descendent.length : 0,
      is_locked: category.is_locked,
      sort: _formatted.length + 1,
      key: `${key}${category.id}|`
    })
    _indexed[category.id] = _formatted.length - 1
    if (!category.descendent || !category.descendent.length) return
    const _root = level === 1 ? category.name_web : root
    const _parent = level > 1 ? (parent + category.name_web + ' / ') : parent
    _formatCategories(
      category.descendent,
      _root,
      _parent,
      level + 1,
      category.picture_mobile || thumb,
      notActive || !category.is_active,
      `${key}${category.id}|`,
      category.id
    )
  })
}

export default {
  getCategories (siteId) {
    const _params = {
      site_id: siteId || 1,
      with_sections: 1,
      types: 'all'
    }
    return ApiUtils.promiseGet(ApiDeleev, '/admin-categories/tree/', { params: _params }).then(function (response) {
      _formatted = []
      _indexed = {}
      _formatCategories(response, '', '', 1, null, false, '|', null)
      return { categories: response, categories_formatted: _formatted, categories_indexed: _indexed }
    })
  }
}
