'use strict'

import { ApiDeleev, ApiUtils } from '../index'

export default {
  login (user) {
    return ApiUtils.promisePost(ApiDeleev, '/auth/login/', user || {})
  },
  checkToken (token) {
    if (!token) {
      return new Promise((resolve, reject) => {
        return reject(new Error('Token not found'))
      })
    }

    return ApiUtils.promiseGet(ApiDeleev, '/admin-user/', { headers: { Authorization: 'Token ' + token } })
  },
  logout () {
    return new Promise((resolve, reject) => {
      // do api logout action
      resolve()
    })
  },
  getUser (id) {
    return ApiUtils.promiseGet(ApiDeleev, `/users/${id}/`)
  }
}
