'use strict'

import mObjects from './objects'

const _date = (date, sliceAt, startAt) => {
  if (!date) return ''
  date = typeof date === 'object' ? date : new Date(date)
  return [date.toLocaleDateString(), date.toLocaleTimeString()].join(' à ').slice(startAt || 0, sliceAt || 10)
}

const _dbDate = (date) => {
  date = typeof date === 'object' ? date : new Date(date)
  date.setHours(12, 0, 0)
  return date.toISOString().slice(0, 10)
}

var _dateRegex = /^(\d{4})([./-])(\d{2})\2(\d{2})$/
const _isValidDate = (date) => {
  const _matchs = date.match(_dateRegex)
  if (!_matchs) return false

  const _date = new Date(date)
  return (Boolean(+_date) && _date.getDate() === +_matchs[4])
}

const _dateOrEmptyString = (date, sliceAt, startAt) => {
  if (date === null) {
    return ''
  }
  return _date(date, sliceAt, startAt)
}

const _weekDays = [
  { value: 1, text: 'Lun.', name: 'monday', full_text: 'Lundi' },
  { value: 2, text: 'Mar.', name: 'tuesday', full_text: 'Mardi' },
  { value: 3, text: 'Mer.', name: 'wednesday', full_text: 'Mercredi' },
  { value: 4, text: 'Jeu.', name: 'thursday', full_text: 'Jeudi' },
  { value: 5, text: 'Ven.', name: 'friday', full_text: 'Vendredi' },
  { value: 6, text: 'Sam.', name: 'saturday', full_text: 'Samedi' },
  { value: 0, text: 'Dim.', name: 'sunday', full_text: 'Dimanche' }
]

const _weekDaysIndexed = mObjects.indexBy(_weekDays, 'value')

const _nextDate = (date, days) => {
  let _date = date ? new Date(date.getTime()) : new Date()
  _date.setDate(_date.getDate() + (days || 1))
  return _date
}
const _tomorrow = () => _nextDate()

const _prevDate = (date, days) => {
  let _date = date ? new Date(date.getTime()) : new Date()
  _date.setDate(_date.getDate() - (days || 1))
  return _date
}
const _yesterday = () => _prevDate()

const _getStartDay = (date) => {
  let _date = date ? new Date(date.getTime()) : new Date()
  _date.setHours(0, 0, 0, 0)
  return _date
}
const _getEndDay = (date) => {
  let _date = date ? new Date(date.getTime()) : new Date()
  _date.setHours(23, 59, 59, 999)
  return _date
}
const _getFirstDayOfMonth = (date) => {
  let _date = date ? new Date(date.getTime()) : new Date()
  return _getStartDay(new Date(_date.getFullYear(), _date.getMonth(), 1))
}
const _getLastDayOfMonth = (date) => {
  let _date = date ? new Date(date.getTime()) : new Date()
  return _getEndDay(new Date(_date.getFullYear(), _date.getMonth() + 1, 0), true)
}
const _prevMonth = (date) => {
  let _date = date ? new Date(date.getTime()) : new Date()
  return _date.getMonth() === 0 ? new Date(_date.getFullYear() - 1, 11, 1) : new Date(_date.getFullYear(), _date.getMonth() - 1, 1)
}
const _nextMonth = (date) => {
  let _date = date ? new Date(date.getTime()) : new Date()
  return _date.getMonth() === 11 ? new Date(_date.getFullYear() + 1, 0, 1) : new Date(_date.getFullYear(), _date.getMonth() + 1, 1)
}

export default {
  isValidDate: _isValidDate,
  date: _date,
  dbDate: _dbDate,
  dateOrEmptyString: _dateOrEmptyString,
  weekDays: () => _weekDays,
  weekDaysIndexed: () => _weekDaysIndexed,
  prevDate: _prevDate,
  nextDate: _nextDate,
  yesterday: _yesterday,
  tomorrow: _tomorrow,
  getWeekDay: (date) => _weekDaysIndexed[date.getDay()],
  getStartDay: _getStartDay,
  getEndDay: _getEndDay,
  getFirstDayOfMonth: _getFirstDayOfMonth,
  getLastDayOfMonth: _getLastDayOfMonth,
  prevMonth: _prevMonth,
  nextMonth: _nextMonth
}
