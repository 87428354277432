'use strict'

import Vue from 'vue'
import VueRouter from 'vue-router'
import store from './store.js'

import VueLogin from './views/Login.vue'
import VueHome from './views/Home.vue'
import VueNotFound from './views/NotFound.vue'
import VueNotAuthorized from './views/NotAuthorized.vue'
// async chunk: group readfaq
const VueFaqRead = () => import(/* webpackChunkName: "group-readfaq" */ './views/FaqRead.vue')
// async chunk: group-users
const VueUsers = () => import(/* webpackChunkName: "group-users" */ './views/Users.vue')
const VueExportUsers = () => import(/* webpackChunkName: "group-users" */ './views/ExportUsers.vue')
// const VueUser = () => import(/* webpackChunkName: "group-users" */ './views/User.vue')
// const VueUserModal = () => import(/* webpackChunkName: "group-users" */ './views/UserModal.vue')
// async chunk: group-promocodes
const VuePromoCodes = () => import(/* webpackChunkName: "group-promocodes" */ './views/PromoCodes.vue')
const VuePromoCode = () => import(/* webpackChunkName: "group-promocodes" */ './views/PromoCode.vue')
const VuePromoCodeModal = () => import(/* webpackChunkName: "group-promocodes" */ './views/PromoCodeModal.vue')
// async chunk: group faq
const VueFaq = () => import(/* webpackChunkName: "group-faq" */ './views/Faq.vue')
// async chunk: group utils
const VueTrackingUrl = () => import(/* webpackChunkName: "group-utils" */ './components/utils/TrackingUrl.vue')
// async chunk: group a/b test
const VueAbTesting = () => import(/* webpackChunkName: "group-abtest" */ './views/AbTesting.vue')
// async chunk: group-postalcode
const VuePostalcode = () => import(/* webpackChunkName: "group-postalcode" */ './views/Postalcode.vue')
// async chunk: group-nps
const VueNPS = () => import(/* webpackChunkName: "group-nps" */ './views/NPS.vue')
// async chunk: group-pages
const VuePages = () => import(/* webpackChunkName: "group-pages" */ './views/Pages.vue')
// async chunk: group-products-racall
const VueProductsRecall = () => import(/* webpackChunkName: "group-products-racall" */ './views/ProductsRecall.vue')
// async chunk: group-sitesconfig
const VueSitesConfig = () => import(/* webpackChunkName: "group-sitesconfig" */ './views/SitesConfig.vue')
// async chunk: group-frichtidisableaccount
const FrichtiDisableAccount = () => import(/* webpackChunkName: "group-frichtidisableaccount" */ './views/FrichtiDisableAccount.vue')
// async chunk: group-chat
const VueChat = () => import(/* webpackChunkName: "group-chat" */ './views/Chat.vue')
const VueCrisp = () => import(/* webpackChunkName: "group-crisp" */ './views/CrispChat.vue')

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '/login',
      component: VueLogin,
      name: 'login'
    },
    {
      path: '/autolog/:token',
      name: 'autolog'
    },
    {
      path: '/',
      name: 'home',
      component: VueHome
    },
    {
      path: '/mes-faq',
      name: 'mes-faq',
      component: VueFaqRead
    },
    {
      path: '/users',
      name: 'users',
      component: VueUsers,
      meta: { permission: 'admin' }
    },
    /*
    {
      path: '/users/:id',
      name: 'user',
      component: VueUsers,
      beforeEnter: (to, from, next) => {
        if (['users'].indexOf(from.name) !== -1) {
          to.matched[0].components.default = from.matched[0].components.default
          to.matched[0].components.modal = VueUserModal
        } else {
          to.matched[0].components.default = VueUser
          to.matched[0].components.modal = false
        }
        next()
      },
      meta: { permission: 'admin' }
    },
    */
    {
      path: '/users/registrations',
      name: 'users-registrations',
      component: VueExportUsers,
      meta: { permission: 'super-admin' }
    },
    {
      path: '/promocodes',
      name: 'promocodes',
      component: VuePromoCodes,
      meta: { permission: ['admin', 'support'] }
    },
    {
      path: '/promocodes/:id',
      name: 'promocode',
      component: VuePromoCodeModal,
      beforeEnter: (to, from, next) => {
        if (['promocodes'].indexOf(from.name) !== -1) {
          to.matched[0].components.default = from.matched[0].components.default
          to.matched[0].components.modal = VuePromoCodeModal
        } else {
          to.matched[0].components.default = VuePromoCode
          to.matched[0].components.modal = false
        }
        next()
      },
      meta: { permission: ['admin', 'support'] }
    },
    {
      path: '/postalcodes',
      name: 'postalcodes',
      component: VuePostalcode,
      meta: { permission: 'admin' }
    },
    {
      path: '/nps',
      name: 'nps',
      component: VueNPS,
      meta: { permission: 'super-admin' }
    },
    {
      path: '/faq',
      name: 'faq',
      component: VueFaq,
      meta: { permission: 'super-admin' }
    },
    {
      path: '/pages',
      name: 'pages',
      component: VuePages,
      meta: { permission: 'super-admin' }
    },
    {
      path: '/products-recall',
      name: 'products-recall',
      component: VueProductsRecall,
      meta: { permission: 'admin' }
    },
    {
      path: '/sites-config',
      name: 'sites-config',
      component: VueSitesConfig,
      meta: { permission: 'super-admin' }
    },
    {
      path: '/frichti-disable-account',
      name: 'frichti-disable-account',
      component: FrichtiDisableAccount,
      meta: { permission: 'admin' }
    },
    {
      path: '/utils/tracking-url',
      name: 'tracking-url',
      component: VueTrackingUrl
    },
    {
      path: '/ab-test',
      name: 'ab-test',
      component: VueAbTesting,
      meta: { permission: 'super-admin' }
    },
    {
      path: '/chat',
      name: 'chat',
      component: VueChat,
      meta: { permission: 'super-admin' }
    },
    {
      path: '/crisp',
      name: 'crisp',
      component: VueCrisp,
      meta: { permission: 'super-admin' }
    },
    {
      path: '/not-authorized',
      name: '401',
      component: VueNotAuthorized
    },
    {
      path: '*',
      name: '404',
      component: VueNotFound
    }
  ]
})

router.beforeEach((to, from, next) => {
  if (to.name === 'autolog') {
    if (to.params.token) {
      let _expireTime = new Date()
      _expireTime.setDate(_expireTime.getDate() + 1)
      Vue.$cookies.set(
        'api_token',
        to.params.token,
        _expireTime,
        '/',
        '.deleev.com'
      )
      // force reload page
      window.location.href = '/'
    } else {
      next({ path: '/' })
    }
    return
  }

  if (!store.isLogged() && to.name !== 'login') {
    next({ path: '/login', query: { redirect: to.fullPath } })
  } else if (store.isLogged() && to.name === 'login') {
    next({ path: '/' })
  } else {
    if (to.meta && to.meta.permission) {
      if (to.meta.permission instanceof Array) {
        const userCan = to.meta.permission.some(p => store.userCan(p))
        userCan ? next() : next({ path: '/not-authorized' })
      } else {
        store.userCan(to.meta.permission) ? next() : next({ path: '/not-authorized' })
      }
    } else {
      next()
    }
  }
})

export default router
