'use strict'

import mObject from './objects'

const _codetypes = [
  {
    value: 'uniq',
    text: 'Code unique',
    html: 'Code unique',
    amount: true,
    percent: false,
    min_amount_basket: false,
    since_days: false,
    is_reusable: false,
    user: true,
    active: true
  },
  {
    value: 'reusable_amount_basket',
    text: 'Montant sur le panier',
    html: 'Montant sur le panier',
    amount: true,
    percent: false,
    min_amount_basket: true,
    since_days: false,
    is_reusable: true,
    user: false,
    active: true
  },
  {
    value: 'reusable_pc_basket',
    text: '% sur le panier',
    html: '% sur le panier',
    amount: false,
    percent: true,
    min_amount_basket: true,
    since_days: false,
    is_reusable: true,
    user: false,
    active: true
  },
  {
    value: 'reusable_first_order',
    text: '1ère commande (montant sur le panier)',
    html: '1<sup>ère</sup> commande (montant sur le panier)',
    amount: true,
    percent: false,
    min_amount_basket: true,
    since_days: false,
    is_reusable: false,
    user: false,
    active: true
  },
  {
    value: 'reusable_first_order_pc_basket',
    text: '1ère commande (% sur le panier)',
    html: '1<sup>ère</sup> commande (% sur le panier)',
    amount: false,
    percent: true,
    min_amount_basket: true,
    since_days: false,
    is_reusable: false,
    user: false,
    active: true
  },
  {
    value: 'reusable_without_order_since',
    text: 'Clients, sans commande depuis x j.',
    html: 'Clients, sans commande depuis x j.',
    amount: true,
    percent: false,
    min_amount_basket: true,
    since_days: true,
    is_reusable: true,
    user: false,
    active: true
  },
  {
    value: 'reusable_mobile_any_order',
    text: 'Clients, aucune commande mobile (montant sur le panier)',
    html: 'Clients, aucune commande mobile (montant sur le panier)',
    amount: true,
    percent: false,
    min_amount_basket: true,
    since_days: false,
    is_reusable: true,
    user: false,
    active: true
  },
  {
    value: 'reusable_mobile_any_order_pc_basket',
    text: 'Clients, aucune commande mobile (% sur le panier)',
    html: 'Clients, aucune commande mobile (% sur le panier)',
    amount: false,
    percent: true,
    min_amount_basket: true,
    since_days: false,
    is_reusable: false,
    user: false,
    active: true
  }
]

const _codetargets = mObject.sort([
  { value: 1, text: 'Indéfinie', order: 1 },
  { value: 2, text: 'Mailing', order: 2 },
  { value: 3, text: 'Mailing auto', order: 3 },
  { value: 4, text: 'Street Marketing', order: 4 },
  { value: 5, text: 'Porte à porte', order: 5 },
  { value: 6, text: 'Pub - Google Ads', order: 6 },
  { value: 7, text: 'Pub - FB Ads', order: 7 },
  { value: 8, text: 'Pub - Pinterest Ads', order: 8 },
  { value: 9, text: 'Pub - Snapchat Ads', order: 9 },
  { value: 10, text: 'Partenariat', order: 12 },
  { value: 11, text: 'Marque', order: 13 },
  { value: 12, text: 'Flyer event', order: 15 },
  { value: 13, text: 'B to B', order: 18 },
  { value: 14, text: 'Pub - Affiliation', order: 10 },
  { value: 15, text: 'Pub - Instagram', order: 11 },
  { value: 16, text: 'Notification mobile', order: 16 },
  { value: 17, text: 'SMS', order: 17 },
  { value: 18, text: 'Autres networks', order: 14 },
  { value: 19, text: 'Marketing', order: 19 },
  { value: 20, text: 'Collaborateur', order: 20 }
], 'order')

const _canteenTypes = [
  { value: 'cantine', text: 'Cantine', variant: 'warning' },
  { value: 'cafet', text: 'Cafet', variant: 'success' },
  { value: 'b2c', text: 'B2C', variant: 'info' }
  // { value: 'kiosk', text: 'Kiosque', variant: 'success' }
]

export default {
  getCodetypes: () => _codetypes,
  codesHas: (attr) => _codetypes.filter(o => !!o[attr]).map(o => o.value),
  getCodetargets: () => _codetargets,
  getCanteenTypes: () => _canteenTypes,
  getCanteenType: (type) => _canteenTypes.find(o => o.value === type) || { value: type, text: '?' }
}
