'use strict'

/* eslint-disable object-property-newline */
/* eslint-disable no-dupe-keys */
const _deburr = {
  'Š': 'S', 'š': 's', 'Đ': 'Dj', 'đ': 'dj', 'Ž': 'Z', 'ž': 'z', 'Č': 'C', 'č': 'c', 'Ć': 'C', 'ć': 'c',
  'À': 'A', 'Á': 'A', 'Â': 'A', 'Ã': 'A', 'Ä': 'A', 'Å': 'A', 'Æ': 'A', 'Ç': 'C', 'È': 'E', 'É': 'E',
  'Ê': 'E', 'Ë': 'E', 'Ì': 'I', 'Í': 'I', 'Î': 'I', 'Ï': 'I', 'Ñ': 'N', 'Ò': 'O', 'Ó': 'O', 'Ô': 'O',
  'Õ': 'O', 'Ö': 'O', 'Ø': 'O', 'Ù': 'U', 'Ú': 'U', 'Û': 'U', 'Ü': 'U', 'Ý': 'Y', 'Þ': 'B', 'ß': 'Ss',
  'à': 'a', 'á': 'a', 'â': 'a', 'ã': 'a', 'ä': 'a', 'å': 'a', 'æ': 'a', 'ç': 'c', 'è': 'e', 'é': 'e',
  'ê': 'e', 'ë': 'e', 'ì': 'i', 'í': 'i', 'î': 'i', 'ï': 'i', 'ð': 'o', 'ñ': 'n', 'ò': 'o', 'ó': 'o',
  'ô': 'o', 'õ': 'o', 'ö': 'o', 'ø': 'o', 'ù': 'u', 'ú': 'u', 'û': 'u', 'ü': 'u', 'ý': 'y',
  'þ': 'b', 'ÿ': 'y', 'Ŕ': 'R', 'ŕ': 'r', 'œ': 'oe',
  '«': ' ', '»': ' ', '"': ' ', '“': ' ', '\'': ' ', '’': ' ', '.': '-', ',': '-', ';': '-',
  '@': ' ', '/': ' ', '(': ' ', ')': ' ',
  '%': ' ', '&': 'et', '°': '', '–': '-', '®': ' ', '…': ' ', '€': 'euros', '$': 'dollars'
}
const _deburrRegex = new RegExp('[' + Object.keys(_deburr).join('') + ']', 'g')
const _deburrOnly = str => {
  if (!str) return ''
  return str.replace(_deburrRegex, function (c) { return _deburr[c] }).replace(/[\s]+/g, ' ').trim()
}
const _slugify = str => {
  return _deburrOnly(str).replace(/\s+/g, '-').replace(/[-]+/g, '-').toLowerCase()
}

const _capitalize = str => {
  if (typeof str !== 'string') return ''
  return str.charAt(0).toUpperCase() + str.slice(1)
}
var _capitalizeFirsts = function (str) {
  return str.toLowerCase().replace('\t', ' ').replace(/(^| )(\w)/g, x => x.toUpperCase())
}

const _escapeHtml = (unsafe) => {
  return unsafe
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/"/g, '&quot;')
    .replace(/'/g, '&#039;')
}

const _escapeRegex = (unsafe) => {
  return unsafe.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&')
}

const _highlight = (str, highlight, tag) => {
  if (!highlight) return str
  tag = tag || 'mark'
  return str.replace(
    new RegExp(`(${_escapeRegex(highlight)})`, 'ig'),
    (match, p1) => `<${tag}>${_escapeHtml(p1)}</${tag}>`
  )
}

const _isNumber = (str) => {
  return /^\d+$/.test(str)
}

const _pad = (n, len) => {
  len = len || 2
  return ('000000000000'.substring(0, len) + n).substring(('' + n).length)
}

const _downloadDataAttachment = (csvdata, filename) => {
  if (window.navigator.msSaveOrOpenBlob) {
    var blob = new Blob([csvdata])
    window.navigator.msSaveOrOpenBlob(blob, filename)
  } else {
    let $a = document.createElement('a')
    $a.href = 'data:attachment/csv;charset=utf-8,%EF%BB%BF' + encodeURIComponent(csvdata)
    $a.target = '_blank'
    $a.download = filename
    document.body.appendChild($a)
    $a.click()
  }
}

const _generateRandomString = () => {
  return Math.floor(Math.random() * Date.now()).toString(36)
}

export default {
  capitalize: _capitalize,
  capitalizeFirsts: _capitalizeFirsts,
  deburr: _deburrOnly,
  slugify: _slugify,
  pad: _pad,
  escapeHtml: _escapeHtml,
  escapeRegex: _escapeRegex,
  highlight: _highlight,
  isNumber: _isNumber,
  downloadDataAttachment: _downloadDataAttachment,
  generateRandomString: _generateRandomString
}
