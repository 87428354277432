'use strict'

import dates from './mixins/dates'
import string from './mixins/string'
import numbers from './mixins/numbers'
import objects from './mixins/objects'
import centers from './mixins/centers'
import sites from './mixins/sites'
import users from './mixins/users'
import suppliers from './mixins/suppliers'
import brands from './mixins/brands'
import promocodes from './mixins/promocodes'
import canteens from './mixins/canteens'

const _allMethods = [
  { prefix: 'dates', methods: dates },
  { prefix: 'string', methods: string },
  { prefix: 'numbers', methods: numbers },
  { prefix: 'objects', methods: objects },
  { prefix: 'centers', methods: centers },
  { prefix: 'sites', methods: sites },
  { prefix: 'users', methods: users },
  { prefix: 'suppliers', methods: suppliers },
  { prefix: 'brands', methods: brands },
  { prefix: 'promocodes', methods: promocodes },
  { prefix: 'canteens', methods: canteens }
]
let _methods = {}
_allMethods.forEach((o) => {
  Object.keys(o.methods).forEach((name) => {
    _methods[o.prefix + string.capitalize(name)] = o.methods[name]
  })
})

export default {
  methods: _methods
}
