<template>
  <header class="d-print-none">
    <b-navbar variant="light" fixed="top" class="py-0">
      <router-link to="/" class="navbar-brand text-deleev">Deleev</router-link>
      <b-collapse is-nav>
        <b-navbar-nav class="d-none d-sm-flex">
          <b-nav-item v-if="userIsAdmin" :class="{ active : isActiveRoute('users') }" to="/users" :disabled="isActiveRoute('users')">Clients</b-nav-item>
          <b-nav-item v-if="userIsAdmin || userIsSupport" :class="{ active : isActiveRoute('promocodes') }" to="/promocodes" :disabled="isActiveRoute('promocodes')">Codes promos</b-nav-item>
          <b-nav-item v-if="userIsAdmin" :class="{ active : isActiveRoute('postalcodes') }" to="/postalcodes" :disabled="isActiveRoute('postalcodes')">Codes postaux</b-nav-item>
          <b-nav-item v-if="userIsSuperAdmin" :class="{ active : isActiveRoute('faq') }" to="/faq" :disabled="isActiveRoute('faq')" class="mb-1">FAQ (admin)</b-nav-item>
          <b-nav-item :class="{ active : isActiveRoute('mes-faq') }" to="/mes-faq" :disabled="isActiveRoute('mes-faq')" class="mb-1">FAQ</b-nav-item>
          <b-nav-item-dropdown ref="utilsmenu" text="Utilitaires" :class="{ active : isActiveRoutes(['tracking-url']) }" class="team-dropdown-wrap" menu-class="team-dropdown">
            <b-dropdown-item v-if="userIsSuperAdmin" :active="isActiveRoute('nps')" to="/nps">NPS Auto</b-dropdown-item>
            <b-dropdown-item v-if="userIsSuperAdmin" :active="isActiveRoute('pages')" to="/pages">Pages</b-dropdown-item>
            <b-dropdown-item v-if="userIsAdmin" :active="isActiveRoute('products-recall')" to="/products-recall">Rappel produits</b-dropdown-item>
            <b-dropdown-item v-if="userIsSuperAdmin" :active="isActiveRoute('sites-config')" to="/sites-config">Configuration des sites</b-dropdown-item>
            <b-dropdown-divider v-if="userIsSuperAdmin"></b-dropdown-divider>
            <b-dropdown-item :active="isActiveRoute('tracking-url')" to="/utils/tracking-url">URL de tracking</b-dropdown-item>
          </b-nav-item-dropdown>
          <!--<b-nav-item v-if="userIsSuperAdmin" :class="{ active : isActiveRoute('ab-test') }" to="/ab-test" :disabled="isActiveRoute('ab-test')" class="mb-1">Test A/B</b-nav-item>-->
        </b-navbar-nav>
        <div class="ml-3">
          <b-button variant="info" size="sm" class="py-0" :href="`${usersFormatSupportLink()}`" target="_blank">Flow > Support</b-button>
        </div>
      </b-collapse>
      <small class="text-muted mr-1">{{user.email}}</small>
      <b-button variant="light" size="sm" class="text-secondary" @click="$emit('logout')"><i class="fas fa-sign-out-alt"></i> déconnexion</b-button>
    </b-navbar>
  </header>
</template>

<script>
import store from '../store'

export default {
  name: 'Header',
  props: {
    user: Object
  },
  watch: {
    $route () {
      if (this.$refs.utilsmenu !== 'undefined') {
        this.$refs.utilsmenu.hide(true)
      }
    }
  },
  data () {
    return {
      userIsSuperAdmin: store.userCan('super-admin'),
      userIsAdmin: store.userCan('admin'),
      userIsSupport: store.userCan('support')
    }
  },
  methods: {
    isActiveRoute (route) {
      return route === this.$route.name
    },
    isActiveRoutes (routes) {
      return routes.find(route => route === this.$route.name)
    }
  }
}
</script>

<style scoped>
  header nav .navbar-brand {
    font-size: 1.54em;
    text-decoration: none;
  }
</style>
