import PubNub from 'pubnub'

let PUBNUB_CHANNEL_GROUP = ''
let PUBNUB_CHANNEL_PREFIX = ''

switch (process.env.NODE_ENV) {
  case 'producttion':
    PUBNUB_CHANNEL_GROUP = 'deleev-support-prod'
    PUBNUB_CHANNEL_PREFIX = 'support-prod'
    break
  case 'preprod':
    PUBNUB_CHANNEL_GROUP = 'deleev-support-preprod'
    PUBNUB_CHANNEL_PREFIX = 'support-preprod'
    break
  case 'development':
    PUBNUB_CHANNEL_GROUP = 'deleev-support-dev'
    PUBNUB_CHANNEL_PREFIX = 'support-dev'
    break
}

export default {
  _pubnub: null,
  getPubnub () {
    if (!this._pubnub) {
      throw new Error('PubNub not initialized')
    }
    return this._pubnub
  },
  connect (pnUserId) {
    if (!this._pubnub) {
      this._pubnub = new PubNub({
        subscribeKey: 'sub-c-e03e2970-f4e7-4ed7-8cb7-47242367cbd5',
        publishKey: 'pub-c-c6293ec2-e19e-4734-a70f-eaf05ae6f9b6',
        userId: pnUserId
        // logVerbosity: true
      })
    }
    return this._pubnub
  },
  setListeners (listeners) {
    const pn = this.getPubnub()
    pn.addListener(listeners)
  },
  async getAllChannelsWithMetadata () {
    const pn = this.getPubnub()
    const [channelsResult, channelsMetadataResult] = await Promise.all([
      pn.channelGroups.listChannels({
        channelGroup: PUBNUB_CHANNEL_GROUP
      }),
      pn.objects.getAllChannelMetadata({
        filter: `id LIKE "${PUBNUB_CHANNEL_PREFIX}*"`
      })
    ])

    const { channels } = channelsResult
    const { data: channelsMetadata } = channelsMetadataResult

    const idsWithMetadata = channelsMetadata.map(c => c.id)

    const channelsWithoutMetadata = channels.filter(
      channel => !idsWithMetadata.includes(channel)
    ).map(id => ({ id }))

    return [...channelsMetadata, ...channelsWithoutMetadata]
  },
  subscribeToSupportGroup () {
    const pn = this.getPubnub()
    return pn.subscribe({
      channelGroups: [PUBNUB_CHANNEL_GROUP],
      withPresence: true
    })
  },
  publishMessage (data) {
    const pn = this.getPubnub()
    return pn.publish({ ...data })
  },
  fetchMessages (channel) {
    const pn = this.getPubnub()
    return pn.fetchMessages({
      channels: [channel],
      includeUUID: true,
      includeMeta: true
    })
  },
  getFileUrl ({ channel, name, id }) {
    const pn = this.getPubnub()
    const url = pn.getFileUrl({ channel, name, id })
    return url
  }

}
