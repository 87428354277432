'use strict'

import axios from 'axios'

export const ApiDeleev = axios.create({
  baseURL: ['production', 'preprod'].indexOf(process.env.NODE_ENV) !== -1 ? process.env.VUE_APP_DELEEV_API_BASE_URL : `/deleev-api`
})

export const ApiLabellevie = axios.create({
  baseURL: ['production', 'preprod'].indexOf(process.env.NODE_ENV) !== -1 ? process.env.VUE_APP_LABELLEVIE_API_BASE_URL : '/labellevie-api'
})

export const ApiProxysearch = axios.create({
  baseURL: process.env.VUE_APP_SEARCH_BASE_URL
})

const _getLabellevieUploadBaseUrl = () => {
  return process.env.VUE_APP_LABELLEVIE_API_BASE_URL.replace('/1.0/api', '')
}

export const ApiDeleevConfig = {
  setAuthorizationToken (token) {
    if (!token) return
    ApiDeleev.defaults.headers.common['Authorization'] = `Token ${token}`
    ApiLabellevie.defaults.headers.common['Authorization'] = `Token ${token}`
  },
  removeAuthorizationToken () {
    delete ApiDeleev.defaults.headers.common['Authorization']
    delete ApiLabellevie.defaults.headers.common['Authorization']
  }
}

export const ApiLabellevieConfig = {
  getHeadersWithByPassToken () {
    return Object.assign({}, ApiLabellevie.defaults.headers.common, { 'x-api-bypass-token': process.env.VUE_APP_DELEEV_X_API_BYPASS_TOKEN })
  }
}

export const ApiProxysearchConfig = {
  setAuthorizationToken (token) {
    if (!token) return
    ApiProxysearch.defaults.headers.common['Authorization'] = `Token ${token}`
  },
  removeAuthorizationToken () {
    delete ApiProxysearch.defaults.headers.common['Authorization']
  }
}

export const ApiUtils = {
  getLabellevieUploadBaseUrl: _getLabellevieUploadBaseUrl,
  promiseGet (httpCLient, path, options) {
    return new Promise((resolve, reject) => {
      (httpCLient || axios).get(path, options)
        .then(response => {
          if (response.status >= 400) reject(response)
          else resolve(response.data)
        })
        .catch(reject)
    })
  },
  promisePost (httpCLient, path, data, options) {
    return new Promise((resolve, reject) => {
      httpCLient.post(path, data, options || {})
        .then(response => {
          if (response.status >= 400) reject(response.data)
          else resolve(response.data)
        })
        .catch(reject)
    })
  },
  promisePut (httpCLient, path, data, options) {
    return new Promise((resolve, reject) => {
      httpCLient.put(path, data, options || {})
        .then(response => {
          if (response.status >= 400) reject(response.data)
          else resolve(response.data)
        })
        .catch(reject)
    })
  },
  promisePatch (httpCLient, path, data, options) {
    return new Promise((resolve, reject) => {
      httpCLient.patch(path, data, options || {})
        .then(response => {
          if (response.status >= 400) reject(response)
          else resolve(response.data)
        })
        .catch(reject)
    })
  },
  promiseDelete (httpCLient, path, options) {
    return new Promise((resolve, reject) => {
      httpCLient.delete(path, options || {})
        .then(response => {
          if (response.status >= 400) reject(response)
          else resolve(response.data)
        })
        .catch(reject)
    })
  }
}
