'use strict'

import { ApiDeleev, ApiUtils } from '../index'

export default {
  getCrispUrl () {
    return ApiUtils.promiseGet(ApiDeleev, '/crisp/authenticate/')
  },
  getConversations () {
    return ApiUtils.promiseGet(ApiDeleev, '/crisp/get_conversations/')
  },
  getMessages (sessionId) {
    return ApiUtils.promiseGet(ApiDeleev, `/crisp/${sessionId}/get_messages/`)
  },
  sendMessage (sessionId, payload) {
    return ApiUtils.promisePost(ApiDeleev, `/crisp/${sessionId}/send_message/`, payload)
  }
}
