'use strict'

import store from '@/store'

const _getSupplier = (supplierId) => {
  return store.getSupplier(supplierId) || { id: supplierId, name: '?' }
}

export default {
  getSupplier: _getSupplier
}
