'use strict'

const _status = [
  { value: 'active', text: 'Active', variant: 'success' },
  { value: 'inactive', text: 'Inactive', variant: 'danger' },
  { value: 'config', text: 'Configuration', variant: 'warning' },
  { value: 'in-progress', text: 'En cours', variant: 'warning' }
]

const _businessTypes = [
  { value: 'kiosk', text: 'Kiosk' },
  { value: 'cafeteria', text: 'Cafet' },
  { value: 'cafet', text: 'Cafet' },
  { value: 'canteen', text: 'Cantine 2.0' }
]

export default {
  getAllStatus: () => _status,
  getStatus: (status) => _status.find(o => o.value === status) || { value: status, text: '?' },
  getBusinessTypes: () => _businessTypes,
  getBusinessTypesForSelect: () => _businessTypes.filter(o => o.value !== 'cafeteria'),
  getBusinessType: (type) => _businessTypes.find(o => o.value === type) || { value: 'canteen', text: 'Canteen 2.0' }
}
