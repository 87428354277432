<template>
  <b-container v-if="!is_prod" class="page-login">
    <div class="pt-2">
      <h1 class="text-deleev text-deleev-title text-center">Deleev</h1>
      <b-card border-variant="light">
        <b-form @submit.prevent="login">
          <b-form-group>
            <label class="small text-muted">E-mail :</label>
            <b-input-group>
              <template v-slot:prepend>
                <span class="bg-light text-muted border"><i class="fas fa-at" /></span>
              </template>
              <b-form-input v-model="email" type="email" required placeholder="e-mail..." />
            </b-input-group>
          </b-form-group>
          <b-form-group>
            <label class="small text-muted">Mot de passe :</label>
            <b-input-group>
              <template v-slot:prepend>
                <span class="bg-light text-muted border"><i class="fas fa-lock" /></span>
              </template>
              <b-form-input v-model="password" type="password" required placeholder="mot de passe..." />
            </b-input-group>
          </b-form-group>
          <b-form-group class="text-center mb-0">
            <b-button type="submit" size="sm" variant="primary">Connexion</b-button>
            <b-form-invalid-feedback :state="validation">E-mail ou mot de passe invalide,<br />re-vérifiez vos paramètres de connexion ou contactez l'administrateur.</b-form-invalid-feedback>
          </b-form-group>
        </b-form>
      </b-card>
    </div>
  </b-container>
</template>

<script>
import store from '../store'
import eventEmitter from '../event-emitter'

export default {
  name: 'Login',
  created () {
    this.is_prod = process.env.NODE_ENV === 'production'

    if (this.is_prod) {
      const _deleevUrl = process.env.VUE_APP_DELEEV_ADMIN_URL
      const _supportsUrl = process.env.VUE_APP_SUPPORTS_ADMIN_URL
      window.location.href = `${_deleevUrl}/account/login?next=${_supportsUrl}${this.$router.currentRoute.query.redirect || '/'}`
    }
  },
  data () {
    return {
      authStatus: '',
      email: '',
      password: ''
    }
  },
  computed: {
    validation () {
      return this.authStatus !== 'error'
    }
  },
  methods: {
    login () {
      this.authStatus = ''
      let email = this.email
      let password = this.password
      store.login({ email, password })
      eventEmitter.on('auth:updated', () => {
        if (store.isLogged()) this.$router.push(this.$router.currentRoute.query.redirect || '/')
        else this.authStatus = 'error'
      })
    }
  }
}
</script>

<style scoped>
.card {
  margin: 1rem auto 0 auto;
  width: 400px;
  max-width: 100%;
}
.card .input-group span {
  min-width: 30px;
  text-align: center;
  font-size: 90%;
  line-height: 28px;
  border-radius: 0.25rem 0 0 0.25rem;
}
</style>
