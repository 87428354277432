<template>
  <b-container class="page-home">
    <div class="text-center pt-4">
      <h1 class="text-deleev text-deleev-title">Deleev</h1>
      <hr />
      <div class="d-md-flex align-items-start justify-content-center">
        <div class="menu-links d-flex flex-column justify-content-center align-items-stretch mx-2">
          <div><router-link v-if="userIsAdmin" to="/users" class="btn btn-sm btn-light mt-1">Clients</router-link></div>
          <div><router-link v-if="userIsSuperAdmin" to="/users/registrations" class="btn btn-sm btn-light mt-1">Export Inscriptions/Commandes</router-link></div>
          <div><router-link v-if="userIsAdmin || userIsSupport" to="/promocodes" class="btn btn-sm btn-light mt-1">Codes promos</router-link></div>
          <div><router-link v-if="userIsAdmin" to="/postalcodes" class="btn btn-sm btn-light mt-1">Codes postaux</router-link></div>
          <div><router-link v-if="userIsSuperAdmin" to="/nps" class="btn btn-sm btn-light mt-1">NPS Auto (> juin 2022)</router-link></div>
          <div><router-link v-if="userIsSuperAdmin" to="/pages" class="btn btn-sm btn-light mt-1">Pages</router-link></div>
          <div><router-link v-if="userIsAdmin" to="/products-recall" class="btn btn-sm btn-light mt-1">Rappel produits</router-link></div>
          <div><router-link v-if="userIsSuperAdmin" to="/sites-config" class="btn btn-sm btn-light mt-1">Configuration des sites</router-link></div>
          <div><router-link v-if="userIsSuperAdmin" to="/ab-test" class="btn btn-sm btn-light mt-1">Résultats Test A/B</router-link></div>
          <div><router-link v-if="userIsSuperAdmin" to="/faq" class="btn btn-sm btn-light mt-1">FAQ (admin)</router-link></div>
          <div><router-link v-if="userIsAdmin" to="/frichti-disable-account" class="btn btn-sm btn-light mt-4">Désabonner (mailing) des e-mails frichti.co</router-link></div>
          <div><router-link to="/mes-faq" class="btn btn-sm btn-light mt-4">FAQ</router-link></div>
        </div>
      </div>
      <div class="mt-5">
        <b-button variant="link" size="sm" class="text-primary text-decoration-none" @click="$emit('logout')"><i class="fas fa-sign-out-alt"></i> déconnexion</b-button>
      </div>
    </div>
  </b-container>
</template>

<script>
import store from '../store'

export default {
  name: 'Home',
  data () {
    return {
      userIsSuperAdmin: store.userCan('super-admin'),
      userIsAdmin: store.userCan('admin'),
      userIsSupport: store.userCan('support')
    }
  }
}
</script>

<style scoped>
  .menu-links > div > a {
    width: 260px;
  }
</style>
