<template>
  <div id="appWrap">
    <VueHeader v-if="isLogged" @logout="logout" :user="user" />
    <router-view v-if="appIsReady" class="app-wrapper" @logout="logout" v-bind:style="{ 'min-height': wrapMinHeight + 'px' }" />
    <router-view v-if="appIsReady" class="app-wrapper-modal" name="modal" />
    <VueFooter v-if="isLogged" />
    <notifications group="supports" position="bottom right" />
  </div>
</template>

<script>
import store from './store'
import eventEmitter from './event-emitter'
import VueHeader from '@/components/Header.vue'
import VueFooter from '@/components/Footer.vue'

export default {
  name: 'App',
  components: {
    VueHeader,
    VueFooter
  },
  data () {
    return {
      wrapMinHeight: 400,
      isLogged: false,
      user: {},
      centers: [],
      sites: []
    }
  },
  created () {
    eventEmitter.on('centers:fetched', this.setCenters)
    eventEmitter.on('sites:fetched', this.setSites)

    eventEmitter.on('auth:updated', this.initApp)
    eventEmitter.on('app:notify', this.notify)
    this.initApp()
  },
  mounted () {
    this.$nextTick(() => {
      window.addEventListener('resize', this.getWrapMinHeight)
      this.getWrapMinHeight()
    })
  },
  computed: {
    appIsReady () {
      return !this.isLogged || (this.centers.length > 0 && this.sites.length > 0)
    }
  },
  methods: {
    getWrapMinHeight () {
      this.wrapMinHeight = window.innerHeight - 115
    },
    notify (data) {
      data = data || {}
      this.$notify({
        group: 'supports',
        title: data.title || 'Notification',
        text: data.message || (data.type === 'error' ? 'Une erreur s\'est produite' : 'Succès :)'),
        type: data.type || 'error'
      })
    },
    logout () {
      store.logout()
      eventEmitter.on('auth:updated', () => {
        if (process.env.NODE_ENV === 'production') {
          window.location.href = `${process.env.VUE_APP_DELEEV_ADMIN_URL}/account/logout`
        } else {
          !store.isLogged() && this.$router.push('/login')
        }
      })
    },
    initApp: function () {
      this.isLogged = store.isLogged()
      this.user = store.getUser()

      if (!this.isLogged) return

      if (store.state.centersFetched !== 'done') store.fetchCenters()
      else this.setCenters()
      if (store.state.sitesFetched !== 'done') store.fetchSites()
      else this.setSites()
    },
    setCenters () {
      if (store.state.centersFetched === 'done') {
        this.centers = store.getCenters()
      }
    },
    setSites () {
      if (store.state.sitesFetched === 'done') {
        this.sites = store.getSites()
      }
    }
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.getWrapMinHeight)

    eventEmitter.off('auth:updated', this.initApp)
    eventEmitter.off('app:notify', this.notify)
  }
}
</script>
