'use strict'

import { ApiDeleev, ApiUtils } from '../index'

const _formatSites = (sites) => {
  sites = sites.filter(o => o.status > 0)
  let _indexed = {}
  sites.forEach((o, idx) => {
    _indexed[o.id] = idx
  })
  let sortedSites = sites.slice()
  sortedSites.sort((a, b) => {
    if (a.is_default) { return -1 }
    if (b.is_default) { return 1 }
    return a.name.localeCompare(b.name)
  })
  return {
    sites: sites,
    sites_indexed: _indexed,
    sortedSites: sortedSites
  }
}
export default {
  getSites () {
    return ApiUtils.promiseGet(ApiDeleev, '/sites/').then(function (response) {
      return _formatSites(response.results.filter(o => o.status > 0))
    })
  }
}
