'use strict'

import eventEmitter from '@/event-emitter'

const _ajaxErrorHandler = (response) => {
  if (typeof response !== 'undefined') {
    if (response instanceof Error) {
      response = {
        data: {
          errors: [response.message]
        }
      }
    } else if (typeof response.response !== 'undefined') {
      response = response.response
    }
  }
  if (typeof response === 'undefined' ||
    typeof response.data === 'undefined' ||
    typeof response.data.errors === 'undefined'
  ) {
    eventEmitter.emit('app:notify', { message: 'No error message.', type: 'error' })
    return
  }
  const errors = response.data.errors
  let errorsString = ''
  if (Array.isArray(errors)) {
    for (let error of errors) {
      // alert(error)
      errorsString += ' ' + error
    }
  }
  if (typeof errors === 'string' || errors instanceof String) {
    errorsString = errors
  }
  if (String(errorsString) === '') {
    eventEmitter.emit('app:notify', { message: 'Erreur inconnue.', type: 'error' })
  } else {
    eventEmitter.emit('app:notify', { message: errorsString, type: 'error' })
  }
}

export default {
  ajaxErrorHandler: _ajaxErrorHandler
}
