'use strict'

import store from '@/store'

const _getCenter = (centerId) => {
  return store.getCenter(centerId) || { id: centerId, name: '?' }
}

export default {
  getCenter: _getCenter
}
