'use strict'

import mString from './string'

const _adminUrl = process.env.VUE_APP_DELEEV_ADMIN_URL
const _flowUrl = process.env.VUE_APP_DELEEV_FLOW_URL

const _formatUserProfilLink = (useId) => {
  return `${_adminUrl}/users/${useId}/`
}

const _formatSupportLink = () => {
  return `${_flowUrl}/support`
}

const _formatUserSupportLink = (useId, email) => {
  return `${_flowUrl}/support/user/${useId}/${email}`
}

const _formatUserOrderLink = (orderId) => {
  return `${_flowUrl}/support/order/${orderId}`
}

const _getFullname = (user) => {
  let _fullname = ((user.last_name || '').toUpperCase() + ' ' + mString.capitalizeFirsts(user.first_name || '')).trim()
  return _fullname || user.email
}

const _registerSources = [
  { value: 'google', text: 'Google Ad Words' },
  { value: 'facebook', text: 'Facebook Ads' }
]

const _deviceSources = [
  { value: 'desktop', text: 'Web' },
  { value: 'mobile', text: 'Web Mobile' },
  { value: 'iphone', text: 'iPhone' },
  { value: 'ipad', text: 'iPad' },
  { value: 'android', text: 'Android' }
]

const _lbvOneSignalApp = 'd661d708-eb67-4453-9e7f-c0712b0df838'
const _bamOneSignalApp = 'ac320b01-84bf-4915-a327-abaafec2497c'
const _frichtiOneSignalApp = 'b95e3a62-77c3-48ce-a236-ffb3cfb6ef0b'
const _formatOneSignalNotificationsLink = (siteId, notificationId) => {
  let _url = `https://onesignal.com/apps`
  let _oneSignalApp = null
  if ([8, 9, 11].indexOf(siteId) !== -1) _oneSignalApp = _bamOneSignalApp
  else if ([1, 10].indexOf(siteId) !== -1) _oneSignalApp = _lbvOneSignalApp
  else if ([1013, 1014].indexOf(siteId) !== -1) _oneSignalApp = _frichtiOneSignalApp
  if (_oneSignalApp) _url += `/${_oneSignalApp}/notifications`
  if (notificationId) _url += `/${notificationId}`
  return _url
}

export default {
  formatUserProfilLink: _formatUserProfilLink,
  formatSupportLink: _formatSupportLink,
  formatUserSupportLink: _formatUserSupportLink,
  formatUserOrderLink: _formatUserOrderLink,
  formatOneSignalNotificationsLink: _formatOneSignalNotificationsLink,
  getFullname: _getFullname,
  registerSources: () => _registerSources,
  getRegisterSource: (source, defaultText) => _registerSources.find(o => o.value === source) || { value: source, text: defaultText || '?' },
  deviceSources: () => _deviceSources,
  getDeviceSource: (device, defaultText) => _deviceSources.find(o => o.value === device) || { value: device, text: defaultText || '?' }
}
