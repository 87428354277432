'use strict'

import store from '@/store'

const _getBrand = (brandId) => {
  return store.getBrand(brandId) || { id: brandId, name: '?' }
}

export default {
  getBrand: _getBrand
}
